.content .select-number{
  padding-top: 10px;
  width: 280px;
  /* height: 188px; */
  margin: 0 auto;
}

.content .select-number > input[type="checkbox"] + label {
  float: left;
  position: relative;
  width: 22px;
  height: 23px;
  margin: 0 3px 10px;
  text-align: center;
  line-height: 23px;
  font-size: 13px;
  color: #f56d6d;
  cursor: pointer;
  letter-spacing: -0.05em;
}

.content .select-number > input[type="checkbox"] {
  position: absolute;
  left: -999em;
}

.content .select-number > input[type="checkbox"]:checked + label {
  background: #555;
  color: #fff;
}

.content .select-number > input[type="checkbox"]:checked + label:before,
.content .select-number > input[type="checkbox"]:checked + label:after {
  display: none;
}

.content .select-number > input[type="checkbox"] + label:after{
  background-position: 0 100%;
  bottom: 0;
}

.content .select-number > input[type="checkbox"] + label:before ,
.content .select-number > input[type="checkbox"] + label:after{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  background-image: url('./bg-check-number.png');
  background-repeat: no-repeat;
}

.content .select-number .action {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.content .select-number .action .checkbox + label
{
  position: relative;
  display: inline-block;
  height: 22px;
  vertical-align: top;
  margin: 0 1px;
  cursor: pointer;
}

.content .select-number .action .checkbox + label span
{
  display: block;
  position: relative;
  z-index: 1;
  padding: 0 5px;
  font-size: 13px;
  height: 22px;
  line-height: 22px;
  color: #f56d6d;
  letter-spacing: -0.05em;
}

.content .select-number .action .checkbox:checked
{
  color: #fff;
  background: #555;
}

.content .select-number .action .checkbox:checked
{
  color: #fff;
  background: #555;
}

.content .btnAction {
  position: absolute;
  top: 340px;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

/* ball 이동 css  */
.moveball {
  text-align: center;
  padding: 20px;
}

.circle-container {
  width: 100px;
  height: 100px;
  background-color: transparent;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.circle {
  /* width: 50px;
  height: 50px;
  background-color: blue;
  border-radius: 50%; */
  position: absolute;
  top: -50px;
  transition: top 2s;
}

.circle.animation {
  animation: moveCircle 2s linear;
  animation-fill-mode: forwards;
}

.circle-container.moving .circle {
  top: 100px;
}